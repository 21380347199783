




























import { Vue, Component, Prop } from 'vue-property-decorator';
import { ColorPallet } from '@/classes/base/elements';

@Component({})
export default class ColorPicker extends Vue {
	@Prop({ default: [] })
	public pallets: ColorPallet[];

	@Prop({ default: 1 })
	public activePalletId: number;

	public displayColorList = false;

	get activePallet(): ColorPallet | undefined {
		const target = this.pallets.find((pallet) => Number(pallet.id) === this.activePalletId);
		return target;
	}
	async openPanel(): Promise<void> {
		if (this.displayColorList === false) {
			this.displayColorList = true;
			setTimeout(() => {
				const list = document.getElementById('listbox');
				if (list) {
					list.focus();
				}
			}, 1);
		} else if (this.displayColorList === true) {
			this.displayColorList = false;
		}
	}

	async closePanel(): Promise<void> {
		setTimeout(() => {
			this.displayColorList = false;
		}, 110);
	}
}
